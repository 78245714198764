:root {
  --gdscRed: #EA4335;
  --gdscBlue: #4285F4;
  --gdscYellow: #f9ab00;
  --gdscGreen: #34a853;

  --iztechRed: #750014;

  --mitRed: #750014;
  --mitLightRed: #ff1423;
  --mitGray: #8b959e;
  --mitDarkGray: #212326;
  --mitBlue: #1966ff;
  --mitLightBlue: #99ebff;
  --mitGreen: #00ad00;

  --legoBlue: #0055BF;
  --legoBlue2: #0059A3;
  --legoGray: #595D60;

  --excalidrawBlue: #366ebc;
  --excalidrawGreen: #2F9E44;
  --excalidrawRed: rgb(193, 52, 52);

   /* purple */
   --darkPurple: rgb(41, 0, 75);
   --darkPurpleHover: rgb(61, 9, 104);
   --purple: rgb(80, 27, 122);
 
   /* white */
   --mainBackground: rgb(241, 243, 245);
   --background: rgb(246, 249, 252);
   --background2: #fafbfc;
 
   /* blue */
   --darkBlue: #5271ff;
   --darkBlueHover: #5271ff88;
   --blueHover: rgb(10, 70, 228);
   --blue: rgb(22, 82, 240);
   --blue2: #2f5dfb;
   --lightBlue: rgb(0, 204, 255);
 
   /* orange */
   --orange: rgb(255, 196, 0);
   --darkorange: #ff7f2e;
 
   /* yellow */
   --yellow: #ffeb4d;
   --darkyellow: #feba2b;
   /* red */
   --red: #ff4631;
 
   /* green */
   --pastelgreen: #1bb778;
 
   /* black */
   --darkbackground: #1a1a1a;
}

body {
  /* background-color: #232323; */
  background-image: linear-gradient(to bottom right, #131313, #232323);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

.header {
  width: 100%;
  margin-top: 4vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header-image-area {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-image {
  width: 200px;
  border-radius: 8px;
}

.header-name-area {
  text-align: center;
  color: var(--excalidrawBlue);
  font-weight: 1000;
  font-size: 24px;
  margin-top: 12px;
}

.header-job-area {
  text-align: center;
  color: #f1f1f1;
  font-weight: 600;
  font-size: 14px;
}

.header-description-area {
  width: 312px;
  color: #f1f1f1;
  margin-top: 24px;
  line-height: 1.4;
}

.header-links-area {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
}

.link {
  margin: auto 8px;
  font-size: 24px;
  color: #000;
  color: #f1f1f1;
  transition: 250ms;
}

.link:hover {
  color: var(--excalidrawBlue);
}

.tech-stack-area, .projects-area {
  width: 312px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 4vh;
}

.tech-stack-languages {
  width: 312px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 24px;
}

.language-div {
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
}

.language-logo {
  /* max-width: 64px; */
  max-height: 64px;
  border-radius: 4px;
}

.tech-stack-text, .projects-text {
  width: 100%;
  color: #888;
  font-weight: 1000;
  font-size: 24px;
  margin-left: -48px;
}

.tech-stack-and-more-text, .projects-and-more-text {
  color: #888;
  font-weight: 600;
  margin-top: 16px;
  text-decoration: none;
  transition: 250ms;
}

.tech-stack-and-more-text:hover, .projects-and-more-text:hover {
  color: var(--excalidrawBlue);
  text-decoration: underline;
}

.projects {
  margin-top: 24px;
}

.project {
  background-color: #161616;
  color: #f1f1f1;
  width: 312px;
  min-height: 96px;
  border-radius: 8px;
  margin-top: 24px;
  padding: 16px;
  margin-left: -16px;
  transition: 250ms;
}

.proejct:first-child {
    margin-top: 0;
}

.project:hover {
  transform: scale(1.05);
}

.project-title {
  font-weight: 800;
  font-size: 16px;
  width: 200px;
}

.project-description {
  margin-top: 8px;
  font-size: 14px;
}


.project-footer {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.project-languages {
  display: flex;
}

.project-language {
  padding: 0 8px;
  height: 18px;
  margin: auto 4px;
  background-color: #232323;
  display: flex;
  font-weight: 200;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 12px;
  color: #f1f1f1;
}

.project-language:first-child {
  margin-left: 0;
}

.project-links {
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-visit-link, .project-github-link {
  color: #f1f1f1;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  transition: 250ms;
}

.project-github-link {
  font-size: 16px;
  margin-left: 12px;
}

.project-visit-link:hover, .project-github-link:hover {
  color: var(--excalidrawBlue);
}

.projects-area {
  margin-bottom: 48px;
}
